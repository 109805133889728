<template>
<v-row class="">
  <template v-for="(proposition, px) in propositionList">
    <v-col cols="12" :key="proposition._id">
      <v-card class="pa-4" :class="'card-border ' + cardColor(proposition) + '--border'">
        <v-row>
          <v-col cols="12" sm="6" class="px-1 py-1">
            <OfferCardSM :offer="proposition.offer"></OfferCardSM>
          </v-col>
          <v-col :key="proposition._id+2" cols="12" sm="6" class="px-1 py-1">
            <v-card color="#171717" outlined :key="px" v-if="proposition.offer != null" class="">

              <v-card-subtitle class="font-papyrus pb-3">
                Vous avez proposé
                <b class="ml-1 teal--text font-led d-inline-block" style="font-size:22px;line-height:32px">
                  {{ proposition.negos[proposition.negos.length-1].amount }} 
                  {{ $store.state.app.monyUnity }}
                </b>
                <br>
                <v-card-subtitle v-if="proposition.negos[proposition.negos.length-1].status == 'OPEN'"
                        dark color="" class="orange--text mt-0 pt-2 px-2">
                  <v-icon color="orange">mdi-reply mdi-rotate-180</v-icon>
                  En attente de réponse
                </v-card-subtitle>
                <v-card-subtitle v-if="proposition.negos[proposition.negos.length-1].status == 'TOLATE'"
                          dark color="red" class="red--text mt-0 pt-2 px-2 font-papyrus">
                    <v-icon color="red">mdi-close</v-icon>
                    Article vendu
                  </v-card-subtitle>
                </v-card-subtitle>
                
                <v-chip v-if="proposition.negos[proposition.negos.length-1].status == 'ACCEPTED'"
                        dark color="green" class="mt-1 ml-4 px-2">
                  <v-icon>mdi-reply mdi-rotate-180</v-icon>
                  <b>Proposition acceptée !</b>
                </v-chip>
                <v-chip v-if="proposition.negos[proposition.negos.length-1].status == 'REFUSED'"
                        dark color="red" class="mt-1 ml-4 px-2">
                  <v-icon>mdi-reply mdi-rotate-180</v-icon>
                  <b>Proposition refusée</b>
                </v-chip>

              <v-card-text>
                <v-card class="pa-3 py-1 my-2 mb-3" color="transparent" style="border:1px solid rgb(58, 57, 57)!important;" outlined elevation="0"
                          v-if="proposition.negos[proposition.negos.length-1].msgTxt || proposition.negos[proposition.negos.length-1].answerTxt">
                  <div class="d-inline-block mb-2" v-if="proposition.negos[proposition.negos.length-1].msgTxt">
                    <div class=" py-0 pt-1 ml-1" v-html="nl2br(proposition.negos[proposition.negos.length-1].msgTxt)"></div>
                  </div>
                  <div class="d-inline-block mb-2" v-if="proposition.negos[proposition.negos.length-1].answerTxt">
                    <v-gravatar height="20" class="mr-1" style="border-radius:50%;margin-bottom:-4px;"
                                  :default-img="'retro'" :email="proposition.offer.creator.name"/>
                    <b>Réponse de {{ proposition.offer.creator.name }}</b>
                    <br>
                    <div class="px-5 py-0 pt-1 ml-1" v-html="nl2br(proposition.negos[proposition.negos.length-1].answerTxt)"></div>
                  </div>
                </v-card>

                <v-btn  block rounded color="blue lighten-1" class="mr-2" v-if="proposition.negos[proposition.negos.length-1].status != 'TOLATE'"
                        outlined @click="startConversation(proposition.offer.creator._id)">
                  <v-icon color="" class="pr-1">mdi-message-outline</v-icon>
                  Contacter le vendeur
                </v-btn>
                <v-btn  rounded color="teal lighten-1" class="mr-2 mt-3" block 
                        v-if="proposition.negos[proposition.negos.length-1].status == 'ACCEPTED'"
                        dark elevation="0" :to="'/payment/'+proposition.offer._id">
                  <v-icon color="" class="pr-1">mdi-credit-card-outline</v-icon>
                  Effectuer le paiement
                </v-btn>
                <v-btn v-if="proposition.negos[proposition.negos.length-1].status == 'ACCEPTED'" 
                        @click="openDialogCancelProp(proposition._id)" 
                        elevation="0" color="orange" block rounded class="mt-3" dark>
                        <v-icon small color="" class="pr-1">mdi-close</v-icon>  Annuler la transaction
                </v-btn>

                <div class="mt-2">
                <DialogProposition v-if="proposition.negos[proposition.negos.length-1].status == 'REFUSED'" 
                                    :offer="proposition.offer" :inDialog="false">
                </DialogProposition>

                <v-btn v-if="proposition.negos[proposition.negos.length-1].status == 'REFUSED'" 
                        @click="openDialogCancelProp(proposition._id)" 
                        elevation="0" color="orange" block rounded class="mt-3" dark>
                        <v-icon small color="" class="pr-1">mdi-close</v-icon>  Annuler la transaction
                </v-btn>
                </div>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
      
    <v-divider class="mt-3" :key="'0'+px"/>
  </template>
  <OfferDialog></OfferDialog>
  
  <v-dialog v-model="showPopupCancelProp" 
            max-width="750" :fullscreen="$vuetify.breakpoint.width < 600">
    <v-card color="black" v-if="currentCancelPropId != null">
      <v-toolbar color="transparent" elevation="0" class=" orange--text">
        <v-icon color="orange" class="mr-2">mdi-close</v-icon> 
        Annuler la transaction ?
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialogCancelProp()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text class="pb-0 mt-5" id="">
        Voulez-vous vraiment abandonner les négociations sur cet article ?
      </v-card-text>
      
      <br>
      <v-divider/>

      <v-toolbar color="transparent" elevation="0" height="80">
        <v-spacer></v-spacer>
        <v-btn text rounded @click="closeDialogCancelProp()" class="mr-2">
          Annuler
        </v-btn>
        <v-btn rounded color="orange" @click="cancelNego()" class="mr-2">
          <v-icon>mdi-close</v-icon> Oui, abandonner
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>

</v-row>
</template>


<script>

import axios from 'axios'
import core from '../../plugins/core.js'
import Gravatar from 'vue-gravatar'

import DialogProposition from '@/components/offer/DialogProposition.vue'
import OfferCardSM from '@/components/offer/OfferCardSM.vue'
import OfferDialog from '@/components/offer/OfferDialog.vue'

export default {
  name: 'home',
  components: {
     'v-gravatar' : Gravatar, DialogProposition, OfferCardSM, OfferDialog
  },
  data: () => ({
    showPopupAnswer: false,
    currentProposition: null,
    currentOffer: null,

    answerText: "",
    showPopupCancelProp: false,
    currentCancelPropId: null
  }),
  mounted: async function(){
    

  },
  methods: {
    async sendAnswer(answerType){
      let params = {  type: answerType, 
                      text: this.answerText,
                      propositionId: this.currentProposition._id 
                   }

      let res = await axios.post("/market/send-answer", params)
      if(res.data.error == false){
        this.showPopupAnswer = false
        this.$store.dispatch('auth/refreshUserData')
      }else{
        console.log("error sending answer")
      }
      this.answerText = ""
    },
    openDialogCancelProp(propId){
      this.currentCancelPropId = propId
      this.showPopupCancelProp = true
    },
    closeDialogCancelProp(){
      this.currentCancelPropId = null
      this.showPopupCancelProp = false
    },
    async cancelNego(){
      let res = await axios.post("/market/cancel-proposition/" + this.currentCancelPropId)
      if(res.data.error == false){
        this.$store.dispatch('auth/refreshUserData')
        this.showPopupCancelProp = false
      }else{
        console.log("error canceling proposition")
      }
    },
    async displayPopupAnswer(offer, proposition){
      this.currentProposition = proposition
      this.currentOffer = offer
      this.showPopupAnswer = true
    },
    baseUrl(){ return core.baseUrl() },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      //let maxLength = 150
      // let p = newStr.length > maxLength ? '...' : ''
      // newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    cardColor(proposition){
      if(proposition.negos[proposition.negos.length-1].status == 'OPEN') return 'orange'
      if(proposition.negos[proposition.negos.length-1].status == 'ACCEPTED') return 'green'
      if(proposition.negos[proposition.negos.length-1].status == 'REFUSED') return 'red'
      return 'blue'
    },
    startConversation(userId){
      this.$store.dispatch('app/openConv', { userId: userId }) 
      this.$root.$emit('closeDialogEntity')
    },
  },
  computed: {
    propositionList(){
      return this.$store.getters['auth/myPropositionsSent']
      // let listAll = this.$store.state.auth.user.propositionsSent
      // console.log("listAll", listAll)
      // return listAll
    },
  },
  watch: {
      
  }, 
}
</script>